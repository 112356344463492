<template>

  <div>
    <b-button
        variant="primary"
        @click="handleShowSidebar"
    >
      <span class="text-nowrap">Nuevo usuario</span>
    </b-button>

    <b-sidebar
        v-model="showSidebar"
        bg-variant="white"
        sidebar-class="sidebar-lg"
        shadow
        backdrop
        no-header
        right
        @hidden="resetForm"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0">
            Nuevo usuario
          </h5>
          <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="handleHideSidebar"
          />
        </div>
        <!--  Body -->
        <b-form
            class="p-2"
        >
          <!-- Nombres -->
          <b-form-group label="Nombres" label-for="usr-nombres">
            <b-form-input
                id="usr-nombres"
                v-model="usuario.usrNombres"
                autofocus
                trim
                maxlength="30"
            />
          </b-form-group>
          <!-- Apellidos -->
          <b-form-group label="Apellidos" label-for="usr-apellidos">
            <b-form-input
                id="usr-apellidos"
                v-model="usuario.usrApellidos"
                autofocus
                trim
                maxlength="30"
            />
          </b-form-group>
          <!-- Correo -->
          <b-form-group label="Correo electrónico" label-for="email">
            <b-form-input
                id="email"
                v-model="usuario.email"
                autofocus
                trim
                maxlength="100"
                type="email"
            />
          </b-form-group>

          <div>
            <b-form-radio-group v-model="usuario.usrType" inline>

              <b-form-radio
                  value="1"
                  class="custom-control-warning"
              >
                Talento humano
              </b-form-radio>
              <b-form-radio
                  value="2"
                  class="custom-control-info"
              >
                Jefatura
              </b-form-radio>

              <b-form-radio
                  value="3"
                  class="custom-control-danger"
              >
                Seguridad
              </b-form-radio>

            </b-form-radio-group>

          </div>


          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                :disabled="isBusy || hasSomeNullEmptyItem"
                @click.prevent="handleSubmit"
            >
              <template v-if="isBusy">
                <b-spinner small/>
                Guardando
              </template>
              <template v-else>
                Guardar
              </template>
            </b-button>
            <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
                :disabled="isBusy"
                @click="handleDiscard"
            >
              Descartar
            </b-button>
          </div>

        </b-form>

        <validation-errors v-if="errors" :errors="errors"/>

      </template>
    </b-sidebar>
  </div>

</template>

<script>

import {
  BButton,
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BSpinner,
  BFormRadioGroup,
  BFormRadio,
} from 'bootstrap-vue'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'

import ValidationErrors from '@/modules/components/ValidationErrors.vue'

import { fetchTypeOptions } from '@/modules/common/utils'

import { mapActions } from 'vuex'

export default {
  name: 'UsuarioNew',
  components:{
    BButton,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BSpinner,
    BFormRadioGroup,
    BFormRadio,
    ValidationErrors,
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  data() {

    return {
      errors: [],
      isBusy: false,
      showSidebar: false,
      usuario: {
        usrNombres: null,
        usrApellidos: null,
        email: null,
        usrType: null
      },
      optionsType: fetchTypeOptions()
    }

  },

  computed: {
    // verificar si algun elemento de un objeto es NULL o vacío
    hasSomeNullEmptyItem () {

      const nullable = ( element ) => element === null || element == ''
      return Object.values( this.usuario ).some( nullable )

    },

  },

  methods: {

    ...mapActions('usuario-module', ['getUsuarios', 'createUsuario']),

    handleShowSidebar(){
      // inicializar
      // mostrar
      this.showSidebar = true
    },


    handleHideSidebar(){

      // inicializar
      this.resetForm()
      // ocultar
      this.showSidebar = false

    },

    // procesar peticion de guardar
    async handleSubmit(){

      try {

        this.errors = []

        this.isBusy = true

        await this.createUsuario( this.usuario )



        await this.getUsuarios()

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Sistema',
            icon: 'CheckIcon',
            text: '¡Usuario creado exitosamente!',
            variant: 'success'
          },
        })

        this.showSidebar = false

      } catch ( error ) {

        if ( error.response.status === 422 ) {

          const  data  = error.response.data.errors

          console.log( "UNXPECTED", error.response.data )

          // errores de validacion
          this.errors = data

        }


      } finally {
        this.isBusy = false
      }

    },

    // procesar peticion de descartar
    handleDiscard(){
      this.showSidebar = false
    },

    // reset los campos del formulario
    resetForm(){
      Object.keys(this.usuario).forEach( key =>  this.usuario[ key ] = null )
    }

    //
  }
}
</script>

<style scoped>

</style>
